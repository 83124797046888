#root .ant-layout {
  height: 100vh;
}

#root .ant-layout-sider {
  max-height: 100vh;
  overflow: auto;
}

#root .ant-layout-sider .ant-layout-sider-children {
  flex-direction: column;
  display: flex;
}

#root .ant-layout-sider .spacer {
  flex: 1;
}

#root .ant-layout-content {
  overflow: auto;
}

.trigger {
  cursor: pointer;
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  transition: color .3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  background: #ffffff4d;
  height: 32px;
  margin: 16px;
}

.dashboard-table {
  width: 100%;
}

.dashboard-table th {
  text-align: right;
  background: #f0f2f5;
}

.dashboard-table td {
  text-align: left;
}

.dashboard-table td, .dashboard-table th {
  border: 1px dotted #666;
  padding: 5px;
}

.dashboard-table tbody > tr:first-child > td, .dashboard-table tbody > tr:first-child > th {
  border-top: 1px solid #333;
}

.dashboard-table tbody > tr:last-child > td, .dashboard-table tbody > tr:last-child > th {
  border-bottom: 1px solid #333;
}

.dashboard-table tr > td:first-child, .dashboard-table tr > th:first-child {
  border-left: 1px solid #333;
}

.dashboard-table tr > td:last-child, .dashboard-table tr > th:last-child {
  border-right: 1px solid #333;
}

.dashboard-table .center {
  text-align: center;
}

.dashboard-table .right {
  text-align: right;
}

.dashboard-table .left {
  text-align: left;
}

.ant-form-item .ant-form-item-label > label {
  white-space: pre-wrap !important;
}
/*# sourceMappingURL=index.9869cb27.css.map */
