#root .ant-layout {
    height: 100vh;

}

#root .ant-layout-sider {
    max-height: 100vh;
    overflow: auto;

    .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
    }

    .spacer {
        flex: 1;
    }
}

#root .ant-layout-content {
    overflow: auto;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.dashboard-table {
    width: 100%;

    th {
        background: #f0f2f5;
        text-align: right;
    }

    td {
        text-align: left;
    }

    td,
    th {
        padding: 5px;
        border: #666 dotted 1px;
    }

    tbody>tr:first-child>td,
    tbody>tr:first-child>th {
        border-top: #333 solid 1px;
        ;
    }

    tbody>tr:last-child>td,
    tbody>tr:last-child>th {
        border-bottom: #333 solid 1px;
        ;
    }


    tr>td:first-child,
    tr>th:first-child {
        border-left: #333 solid 1px;
        ;
    }

    tr>td:last-child,
    tr>th:last-child {
        border-right: #333 solid 1px;
        ;
    }

    .center {
        text-align: center;
    }

    .right {
        text-align: right;
    }

    .left {
        text-align: left;
    }
}

.ant-form-item .ant-form-item-label>label {
    white-space: pre-wrap !important;
}